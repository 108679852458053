<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <h2 class="is-3 title">
            {{ $t('Calculation Adjustment') }}
          </h2>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container">
        <CalculationAdjustmentForm
          :is-submitting="isSubmitting"
          :calculation-adjustment="adjustment"
          @submit="handleSave"
        />
        <div
          style="max-width: 300px; margin: auto;"
        >
          <a-alert
            v-if="isSaved"
            type="success"
          >
            <template #message>
              <span v-html="$t('calculation-adjustment-saved')" />
            </template>
          </a-alert>
          <a-alert
            v-if="error"
            :message="error"
            type="error"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Calculation Adjustment": "Calculation Adjustment",
    "calculation-adjustment-saved": "Calculation adjustment is saved."
  },
  "ja": {
    "Calculation Adjustment": "Calculation Adjustment",
    "calculation-adjustment-saved": "Calculation adjustment is saved."
  }
}
</i18n>

<script>
import CalculationAdjustmentForm from '@/views/simulations/components/calculation-adjustment/Form';

export default {
  name: 'CalculationAdjustment',
  components: {
    CalculationAdjustmentForm,
  },
  data() {
    return {
      isSubmitting: false,
      error: null,
      isSaved: false,
      adjustment: {},
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.$store.dispatch('simulations/getCalculationAdjustment').then(({ data }) => {
        this.adjustment = data;
        this.$store.commit('HIDE_FULLSCREEN_LOADER');
      }).catch(() => {
        this.$store.commit('HIDE_FULLSCREEN_LOADER');
      });
    },
    handleSave(data) {
      this.isSubmitting = true;
      this.$store.dispatch('simulations/saveCalculationAdjustment', { ...data })
        .then(() => {
          this.isSaved = true;
          this.isSubmitting = false;
        }).catch((err) => {
          this.isSubmitting = false;
          if (err.response && err.response.data && err.response.data.error) {
            this.error = err.response.data.error.message;
          }
        });
    },
  },
};
</script>
