<template>
  <div class="field is-horizontal">
    <div class="field-label mr-3">
      <label class="label">{{ label }}</label>
    </div>
    <div class="field-body is-horizontal is-wrapped">
      <div
        v-if="numberField <= 1"
        class="is-100-field"
      >
        <a-form-item
          ref="itemField"
          class="custom-validation mb-0"
          :name="name"
        >
          <a-input
            v-model:value="modifiedValue"
            :size="size"
            :type="type"
            step="any"
          />
        </a-form-item>
      </div>
      <div
        v-for="(field, index) in numberField"
        v-else
        :key="index"
        class="is-smaller-field"
      >
        <a-form-item
          ref="itemField"
          class="custom-validation mb-0"
          :name="name"
        >
          <a-input
            v-model:value="modifiedValue[index]"
            :size="size"
            :type="type"
            max="2100"
          />
        </a-form-item>
      </div>
      <span class="is-align-items-center is-flex pl-3">{{ unit }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputField',
  props: {
    size: {
      type: String,
      default: 'large',
    },
    type: {
      type: String,
      default: 'text',
    },
    unit: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    value: {
      type: String || Number || Array,
      default: '',
    },
    numberField: {
      type: Number,
      default: 1,
    },
  },
  emits: ['update:value'],
  data() {
    return {
      error: null,
    };
  },
  computed: {
    modifiedValue: {
      set(nv) {
        this.$emit('update:value', nv);
      },
      get() {
        return this.value;
      },
    },
  },
};
</script>

<style scoped>
:deep(.custom-validation .ant-form-explain) {
  width: 300px;
  margin-left: -200px;
  text-align: end;
}
.field {
  margin-top: 10px;
}
.is-horizontal {
  display: flex;
}
.is-horizontal.is-wrapped {
  flex-wrap: wrap;
}
.field-label {
  width: 220px;
  display: flex;
  align-items: center;
}
.is-100-field {
  width: 100px;
}
.is-smaller-field {
  width: 70px;
  margin-right: 5px;
  margin-bottom: 5px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  text-align: right;
}

.year-input input[type=number] {
  text-align: left;
}

@media screen and (max-width: 480px) {
  .field.is-horizontal {
    display: block;
  }
}
</style>
