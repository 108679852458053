<template>
  <a-form
    ref="calculationAdjustment"
    :model="form"
    :rules="rules"
  >
    <div class="columns">
      <div class="column">
        <InputField
          v-model:value="form.indexForSoldRatio"
          type="number"
          unit="%"
          name="indexForSoldRatio"
          :label="$t('Adjustment Index for Sold Ratio')"
        />
        <InputField
          v-model:value="form.indexForModelOCC"
          type="number"
          unit="%"
          name="indexForModelOCC"
          :label="$t('Adjustment Index for Model OCC')"
        />
        <InputField
          v-model:value="form.indexForAddonDOR"
          type="number"
          name="indexForAddonDOR"
          :label="$t('Adjustment Index for Addon DOR')"
        />
        <InputField
          v-model:value="form.indexForBaseDOR"
          type="number"
          name="indexForBaseDOR"
          :label="$t('Adjustment Index for Base  DOR')"
        />
        <InputField
          v-model:value="form.peakOfRoomSqm"
          type="number"
          name="peakOfRoomSqm"
          :unit="$t('Sqm')"
          :label="$t('Peak of Room Sqm')"
        />
        <InputField
          v-model:value="form.indexForRoomBaseADR"
          type="number"
          name="indexForRoomBaseADR"
          :label="$t('Adjustment Index for Room Base ADR')"
        />
        <InputField
          v-model:value="form.indexForExcessiveSqm"
          type="number"
          name="indexForExcessiveSqm"
          :label="$t('Adjustment Index for Excessive Sqm')"
        />
        <InputField
          v-model:value="form.indexForRoomPeakedSqm"
          type="number"
          name="indexForRoomPeakedSqm"
          :label="$t('Adjustment Index for Room Peaked Sqm')"
        />
        <InputField
          v-model:value="form.indexForAverageGuestPerRoom"
          type="number"
          name="indexForAverageGuestPerRoom"
          :label="$t('Adjustment Index for Average Guest Per Room (AGPR)')"
        />
        <InputField
          v-model:value="form.indexForBeddingLift"
          type="number"
          name="indexForBeddingLift"
          :label="$t('Adjustment Index for Bedding Lift')"
        />
        <InputField
          v-model:value="form.benchmarkForExceedingPrivatelyOccupiedSpace"
          type="number"
          name="benchmarkForExceedingPrivatelyOccupiedSpace"
          unit="%"
          :label="$t('Benchmark for Exceeding Privately Occupied Space')"
        />
        <InputField
          v-model:value="form.indexForPrivatelyOccupiedSpaceExceeding"
          type="number"
          name="indexForPrivatelyOccupiedSpaceExceeding"
          unit="%"
          :label="$t('Adjustment Index for Privately Occupied Space Exceeding')"
        />
        <InputField
          v-model:value="form.benchmarkForPeakedPrivatelyOccupiedSpace"
          type="number"
          name="benchmarkForPeakedPrivatelyOccupiedSpace"
          unit="%"
          :label="$t('Benchmark for Peaked Privately Occupied Space')"
        />
        <InputField
          v-model:value="form.indexForPeakedPrivatelyOccupiedSpace"
          type="number"
          name="indexForPeakedPrivatelyOccupiedSpace"
          unit="%"
          :label="$t('Adjustment Index for Peaked Privately Occupied Space')"
        />
      </div>
      <div class="column">
        <InputField
          v-model:value="form.tax"
          type="number"
          name="tax"
          unit="%"
          :label="$t('Tax')"
        />
        <InputField
          v-model:value="form.competitorIndexForMealAndStayPlan"
          type="number"
          name="competitorIndexForMealAndStayPlan"
          unit="%"
          :label="$t('Competitor Index for Meal & Stay Plan')"
        />
        <InputField
          v-model:value="form.competitorIndexForRoomOnlyPlan"
          type="number"
          name="competitorIndexForRoomOnlyPlan"
          unit="%"
          :label="$t('Competitor Index for Room Only Plan')"
        />
        <InputField
          v-model:value="form.beddingCountOfDoubleBed"
          type="number"
          name="beddingCountOfDoubleBed"
          :label="$t('Bedding Count of Double Bed')"
        />
        <InputField
          v-model:value="form.beddingCountOfSingleBed"
          type="number"
          name="beddingCountOfSingleBed"
          :label="$t('Bedding Count of Single Bed')"
        />
        <InputField
          v-model:value="form.beddingCountOfSingleFuton"
          type="number"
          name="beddingCountOfSingleFuton"
          :label="$t('Bedding Count of Single Futon')"
        />
        <InputField
          v-model:value="form.beddingCountOfDoubleFuton"
          type="number"
          name="beddingCountOfDoubleFuton"
          :label="$t('Bedding Count of Double Futon')"
        />
        <InputField
          v-model:value="form.rtMarketShare"
          type="number"
          name="rtMarketShare"
          unit="%"
          :label="$t('RT Market Share')"
        />
        <InputField
          v-model:value="form.discount"
          type="number"
          name="discount"
          unit="%"
          :label="$t('Discount')"
        />
        <InputField
          v-model:value="form.histogramClass"
          type="number"
          name="histogramClass"
          :unit="$t('JPY, tax included price')"
          :label="$t('Histogram Class')"
        />
        <InputField
          v-model:value="form.pastReservationsInYear"
          type="number"
          name="pastReservationsInYear"
          :number-field="5"
          class="year-input"
          :label="$t('Past Reservations in Year')"
        />
        <InputField
          v-model:value="form.pastOCCInYear"
          type="number"
          name="pastOCCInYear"
          :number-field="5"
          class="year-input"
          :label="$t('Past OCC in Year')"
        />
        <InputField
          v-model:value="form.winRateForMealAndStayPlan"
          type="number"
          name="winRateForMealAndStayPlan"
          unit="%"
          :label="$t('Win Rate for Meal & Stay Plan')"
        />
        <InputField
          v-model:value="form.winRateForRoomOnlyPlan"
          type="number"
          name="winRateForRoomOnlyPlan"
          unit="%"
          :label="$t('Win Rate for Room Only Plan')"
        />
        <InputField
          v-model:value="form.breakfastPrice"
          type="number"
          name="breakfastPrice"
          :unit="$t('JPY')"
          :label="$t('Breakfast Price')"
        />
        <InputField
          v-model:value="form.dinnerPrice"
          type="number"
          name="dinnerPrice"
          :unit="$t('JPY')"
          :label="$t('Dinner Price')"
        />
      </div>
    </div>
    <div
      class="column is-12"
      style="text-align: center;"
    >
      <a-button
        :loading="isSubmitting"
        size="large"
        class="m-r-3x"
        style="min-width: 155px;"
        type="primary"
        html-type="submit"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<script>
import InputField from '@/views/simulations/components/calculation-adjustment/InputField';

export default {
  name: 'AdjustmentForm',
  components: {
    InputField,
  },
  props: {
    isSubmitting: {
      type: Boolean,
    },
    calculationAdjustment: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['submit'],
  data() {
    const pastYearArrayValidation = (rule, value, callback) => {
      if (!Array.isArray(value)) {
        return callback(new Error(this.$t('At least one field is required.')));
      }
      const filledValue = value.filter((val) => val !== '');
      if (filledValue.length < 1) {
        return callback(new Error(this.$t('At least one field is required.')));
      }
      const invalidateLength = value.filter((val) => val !== '' && val.length !== 4);
      if (invalidateLength.length > 0) {
        return callback(new Error(this.$t('Year format must have 4 character.')));
      }

      return callback();
    };

    return {
      form: {
        indexForSoldRatio: '',
        indexForModelOCC: '',
        indexForAddonDOR: '',
        indexForBaseDOR: '',
        peakOfRoomSqm: '',
        indexForRoomBaseADR: '',
        indexForExcessiveSqm: '',
        indexForRoomPeakedSqm: '',
        indexForAverageGuestPerRoom: '',
        indexForBeddingLift: '',
        benchmarkForExceedingPrivatelyOccupiedSpace: '',
        indexForPrivatelyOccupiedSpaceExceeding: '',
        benchmarkForPeakedPrivatelyOccupiedSpace: '',
        indexForPeakedPrivatelyOccupiedSpace: '',
        tax: '',
        competitorIndexForMealAndStayPlan: '',
        competitorIndexForRoomOnlyPlan: '',
        beddingCountOfDoubleBed: '',
        beddingCountOfSingleBed: '',
        beddingCountOfSingleFuton: '',
        beddingCountOfDoubleFuton: '',
        rtMarketShare: '',
        discount: '',
        histogramClass: '',
        pastReservationsInYear: ['', '', '', '', ''],
        pastOCCInYear: ['', '', '', '', ''],
        winRateForMealAndStayPlan: '',
        winRateForRoomOnlyPlan: '',
        breakfastPrice: '',
        dinnerPrice: '',
      },
      rules: {
        indexForSoldRatio: [
          {
            required: true,
            message: this.$t('Adjustment Index for sold ratio is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        indexForModelOCC: [
          {
            required: true,
            message: this.$t('Adjustment Index for Model OCC is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        indexForAddonDOR: [
          {
            required: true,
            message: this.$t('Adjustment Index for Addon DOR is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        indexForBaseDOR: [
          {
            required: true,
            message: this.$t('Adjustment Index for Base DOR is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        peakOfRoomSqm: [
          {
            required: true,
            message: this.$t('Peak of Room sqm is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        indexForRoomBaseADR: [
          {
            required: true,
            message: this.$t('Adjustment Index for Room Base ADR  is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        indexForExcessiveSqm: [
          {
            required: true,
            message: this.$t('Adjustment Index for Excessive sqm is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        indexForRoomPeakedSqm: [
          {
            required: true,
            message: this.$t('Adjustment Index for Room\'s Peaked sqm is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        indexForAverageGuestPerRoom: [
          {
            required: true,
            message: this.$t('Adjustment Index for AGPR is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        indexForBeddingLift: [
          {
            required: true,
            message: this.$t('Adjustment Index for Bedding Lift is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        benchmarkForExceedingPrivatelyOccupiedSpace: [
          {
            required: true,
            message: this.$t('Benchmark for Exceeding Privately Occupied Space is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        indexForPrivatelyOccupiedSpaceExceeding: [
          {
            required: true,
            message: this.$t('Index for Privately Occupied Space Exceeding is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        benchmarkForPeakedPrivatelyOccupiedSpace: [
          {
            required: true,
            message: this.$t('Benchmark for Peaked Privately Occupied Space is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        indexForPeakedPrivatelyOccupiedSpace: [
          {
            required: true,
            message: this.$t('Index for Peaked Privately Occupied Space is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        tax: [
          {
            required: true,
            message: this.$t('Tax is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        competitorIndexForMealAndStayPlan: [
          {
            required: true,
            message: this.$t('Competitor Index for Meal & Stay Plan is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        competitorIndexForRoomOnlyPlan: [
          {
            required: true,
            message: this.$t('Competitor Index for Room Only Plan is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        beddingCountOfDoubleBed: [
          {
            required: true,
            message: this.$t('Bedding Count of dbl Bed is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        beddingCountOfSingleBed: [
          {
            required: true,
            message: this.$t('Bedding Count of sgl Bed is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        beddingCountOfSingleFuton: [
          {
            required: true,
            message: this.$t('Bedding Count of sgl Futon is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        beddingCountOfDoubleFuton: [
          {
            required: true,
            message: this.$t('Bedding Count of dbl Futon is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        rtMarketShare: [
          {
            required: true,
            message: this.$t('RT Market Share is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        discount: [
          {
            required: true,
            message: this.$t('Discount is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        histogramClass: [
          {
            required: true,
            message: this.$t('Histogram Class is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        pastReservationsInYear: [
          {
            validator: pastYearArrayValidation,
            trigger: ['change', 'blur'],
          },
        ],
        pastOCCInYear: [
          {
            validator: pastYearArrayValidation,
            trigger: ['change', 'blur'],
          },
        ],
        winRateForMealAndStayPlan: [
          {
            required: true,
            message: this.$t('Win Rate for Meal & Stay Plan is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        winRateForRoomOnlyPlan: [
          {
            required: true,
            message: this.$t('Win Rate for Room Only Plan is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        breakfastPrice: [
          {
            required: true,
            message: this.$t('Breakfast Price is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        dinnerPrice: [
          {
            required: true,
            message: this.$t('Dinner Price is required.'),
            trigger: ['change', 'blur'],
          },
        ],
      },
    };
  },
  watch: {
    calculationAdjustment: {
      handler(nv) {
        const emptyArray = ['', '', '', '', ''];
        const keys = Object.keys(nv);
        if (keys.length > 0) {
          keys.forEach((element) => {
            if (element === 'pastReservationsInYear'
              || element === 'pastOCCInYear') {
              this.form[element] = nv[element];
              return;
            }
            this.form[element] = nv[element].toString();
          });

          if (!Array.isArray(nv.pastReservationsInYear)) {
            this.form.pastReservationsInYear = emptyArray;
          }
          if (!Array.isArray(nv.pastOCCInYear)) {
            this.form.pastOCCInYear = emptyArray;
          }
        }
      },
    },
  },
  methods: {
    handleSubmit() {
      this.$refs.calculationAdjustment.validate().then(() => {
        this.$emit('submit', this.form);
      });
    },
  },
};
</script>

<style scoped>

</style>
